import React from 'react';

export const config1 = () => {
  return (
    <div>config 1</div>
  );
};

export const config2 = () => {
  return (
    <div>config 2</div>
  );
};

export const apiurl = "https://findmykeeper-engine.onrender.com"
// export const apiurl = "http://localhost:7171"