import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white py-8">
      <div className="container mx-auto flex flex-col md:flex-row justify-between items-center">
        {/* Logo */}
        <div className="flex items-center mb-4 md:mb-0">
        <img src="/logo.svg" alt="FindMyKeeper Logo" className=" mx-2 bg-white p-2" />
          {/* <h1 className="text-2xl font-bold">FindMyKeeper</h1> */}
        </div>
        {/* Address and Info */}
        <div className="text-center md:text-left">
          <p className="mb-2">123 Main Street</p>
          <p className="mb-2">City, State, Zip Code</p>
          <p className="mb-2">Phone: 123-456-7890</p>
          <p>Email: info@findmykeeper.com</p>
        </div>
        {/* Social Media Links */}
        <div className="mt-4 md:mt-0">
          <ul className="flex space-x-4">
            <li><a href="#" className="text-white hover:text-orange-500"><i className="fab fa-facebook-f"></i></a></li>
            <li><a href="#" className="text-white hover:text-orange-500"><i className="fab fa-twitter"></i></a></li>
            <li><a href="#" className="text-white hover:text-orange-500"><i className="fab fa-instagram"></i></a></li>
          </ul>
        </div>
      </div>
      {/* Disclaimer */}
      <div className="container mx-auto mt-4 text-center text-sm">
        <p>&copy; 2024 FindMyKeeper. All rights reserved.</p>
        <p>Designed with <span role="img" aria-label="heart">❤️</span> by YourCompany</p>
      </div>
    </footer>
  );
};

export default Footer;
