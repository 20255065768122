import axios from "axios";
import { apiurl } from "./config";
export const validateToken = async (token) => {
  try {
    const response = await axios.post(
      `${apiurl}/user/verifyuser`,
      {},
      {
        headers: {
          Authorization: token,
        },
      }
    );
    if (response.data?.success === true) {
      return response.data;
    } else {
      throw new Error("Token validation failed - Response not successful");
    }
  } catch (error) {
    console.error("Token validation error:", error);
    throw new Error("Token validation failed");
  }
};