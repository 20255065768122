import React from 'react';

const Header = () => {
  return (
    <header className="bg-black text-white py-4">
      <div className="container mx-auto flex justify-between items-center">
        {/* Logo */}
        <div className="flex items-center">
          <img src="/logo.svg" alt="FindMyKeeper Logo" className="h-10 mx-2 bg-white p-2" />
          {/* <h1 className="text-2xl font-bold">FindMyKeeper</h1> */}
        </div>
        {/* Navigation */}
        <nav>
          <ul className="flex space-x-4">
            <li><a href="#" className="hover:text-orange-500">Home</a></li>
            {/* Add more navigation links as needed */}
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
