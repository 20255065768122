import logo from "./logo.svg";
import "./App.css";
import QRCodeGenerator from "./components/QRCodeGenerator";
import RoutePage from "./routes";

function App() {
  return <RoutePage />;
}

export default App;
