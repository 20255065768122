import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Profile from "../pages/profile";
import About from "../pages/about";

import Login from "../pages/login";
import NotFound from "../pages/notFound";
import Unauthorized from "../pages/unauthorized";
import Home from "../pages/home.js";
import Register from "../pages/register.js";

import ProtectedRoute from "../config/ProtectedRoute.js";

import ForgotPass from "../pages/forgotPassword.js";
import VerifyEmail from "../pages/verifyEmail.js";

import UserProfile from "../pages/userProfile.js";
import Setting from "../pages/setting.js";
import Insights from "../pages/insights.js";

const RoutePage = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/profile/:userid/" element={<UserProfile />} />
        <Route path="/forgot-password" element={<ForgotPass />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/unauthorized" element={<Unauthorized />} />
        <Route path="/verifyemail/:userid/:token" element={<VerifyEmail />} />
        <Route path="/about" element={<About />} />
        <Route path="/insights/:codeid" element={<Insights />} />

        <Route
          path="/settings"
          element={<ProtectedRoute element={<Setting />} />}
        />

        <Route
          path="/profile"
          element={<ProtectedRoute element={<Profile />} />}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default RoutePage;
