import React, { useEffect, useState } from "react";
import { Route, Navigate } from "react-router-dom";
import axios from "axios";
import { validateToken } from "./validateToken";
import { checkAccess } from "./protect";
import { getCookie } from "./coockies.js"
import { useUser } from "./UserProvider.js";

// const ProtectedRoute = ({ element: Element, requiredAccess }) => {
const ProtectedRoute = ({ element: Element }) => {
  const { setUser } = useUser();
  const [isValidated, setIsValidated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = getCookie("savage_token");
    if (!token) {
      window.location.href = "/login";
    }

    // console.log("setUser",setUser);

    validateToken(token)
      .then((user) => {
        // const hasAccess = checkAccess(user?.accountType, requiredAccess);
        // console.log(hasAccess);
        //( setIsValidated(hasAccess);
        console.log("user PrivateRoute",user)
        if(user?.success===true){
          setIsValidated(true);
          setUser(user.user);
        }else{
          setIsValidated(false);
        }
      })
      .catch((error) => {
        console.error("Token validation failed:", error);
        setIsValidated(false);
      })
      .finally(() => {
        setLoading(false);
      });
  // }, [requiredAccess]);
  },[]);

  console.log("isValidated:", isValidated);

  if (loading) {
    return (
      <div>
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
          <div className="bg-white p-6 rounded shadow-lg text-center">
            <div className="animate-spin rounded-full h-20 w-20 border-t-2 border-b-2 border-gray-900"></div>
            <p className="mt-4 text-gray-800">Verifying...</p>
          </div>
        </div>
      </div>
    );
  }

  return isValidated ? Element : <Navigate to="/unauthorized" />;
};

export default ProtectedRoute;
