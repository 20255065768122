import React from "react";
import Footer from "../components/footer";
import Header from "../components/header";
import Layout from "../components/layout";

const Home = () => {
  return (
    <Layout>
    <div>

      <section className="bg-gray-900 text-white py-16">
      <div className="container mx-auto">
        {/* Animated Text */}
        <div className="text-center mb-8">
          <h2 className="text-4xl font-bold mb-4">Welcome to FindMyKeeper</h2>
          <p className="text-lg">Discover Your Perfect Match</p>
          <p className="text-lg">Book Trusted Keepers Near You</p>
        </div>
        {/* Publisher Section */}
        <div className="mb-8">
          <marquee behavior="scroll" direction="left">
            <img src="/logo.png" alt="FindMyKeeper Logo" className="h-16 mr-2" />
            {/* Add more logos if needed */}
          </marquee>
        </div>
        {/* Card Sections */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {/* Card 1 */}
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-semibold mb-2">Trusted Keepers</h3>
            <p>Find keepers who are reliable and trustworthy.</p>
          </div>
          {/* Card 2 */}
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-semibold mb-2">Easy Booking</h3>
            <p>Book your keeper with just a few clicks.</p>
          </div>
          {/* Card 3 */}
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-semibold mb-2">24/7 Support</h3>
            <p>We provide round-the-clock customer support.</p>
          </div>
        </div>
      </div>
    </section>
   
    </div>
    </Layout>
  );
};

export default Home;
