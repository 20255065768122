// // ProfileProvider.js
// import React, { createContext, useContext, useState, useEffect } from "react";
// import { getCookie } from "./coockies";
// import { validateToken } from "./validateToken";

// const ProfileContext = createContext();

// const ProfileProvider = ({ children }) => {
//   const [profile, setProfile] = useState(null);
//   const token = getCookie("savage_token");

//   useEffect(() => {
//     const fetchProfile = async () => {
//       try {
//         if (token) {
//           const response = await validateToken(token);
//           console.log("response 17", response);
//           setProfile(response);
//         }
//       } catch (error) {
//         console.error("Error fetching profile:", error);
//       }
//     };

//     fetchProfile();
//   }, [token]);

//   return (
//     <ProfileContext.Provider value={{ profile }}>
//       {children}
//     </ProfileContext.Provider>
//   );
// };

// const useProfile = () => {
//   const context = useContext(ProfileContext);
//   if (!context) {
//     throw new Error("useProfile must be used within a ProfileProvider");
//   }
//   return context;
// };

// export { ProfileProvider, useProfile };


import React, { createContext, useContext, useState } from "react";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userData, setUserData] = useState(null);

  const setUser = (user) => {
    setUserData(user);
  };

  return (
    <UserContext.Provider value={{ userData, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  return useContext(UserContext);
};
