import React, { useEffect, useState } from "react";
import axios from "axios";
import { apiurl } from "../config/config";
import { useParams } from "react-router-dom";
import Layout from "../components/layout";
import Loader from "../components/loader";

const Insights = () => {
  const [userDetails, setUserDetails] = useState(null);
  const [codeStatus, setuCodeStatus] = useState("draft");
  const [message, setMessage] = useState("");
  const [customMessage, setCustomMessage] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const { codeid } = useParams();

  const fetchCodeDetails = () => {
    axios
      .get(`${apiurl}/api/code/get/${codeid}`)
      .then((response) => {
        const data = response?.data?.code;
        // setuCodeStatus(data?.userCodeStatus);
        setLoading(false);
        if (data.userCodeStatus === "active" && !data.active) {
          setError(
            "This code is not connected and not active. Please contact support for activation."
          );
        } else {
          setUserDetails(data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setError("Error fetching data. Please try again later.");
      });
  };

  useEffect(() => {
    fetchCodeDetails();
  }, [codeid]);

  const handleActivateQR = () => {
    // Make Axios GET request to API endpoint
    axios
      .get(`${apiurl}/api/code/${codeid}`)
      .then((response) => {
        const data = response.data;
        if (data.usercode === "active" && !data.active) {
          setError(
            "This code is not connected and not active. Please contact support for activation."
          );
        } else {
          setUserDetails(data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setError("Error fetching data. Please try again later.");
      });
  };

  const handleSubmitMessage = (event) => {
    event.preventDefault();
    const messageToSend = message === "other" ? customMessage : message;
    console.log("Sending message:", messageToSend);

    // You can implement logic to send the message here
  };

  const handleCustomMessageChange = (event) => {
    setCustomMessage(event.target.value);
  };

  const handleCall = () => {
    // Handle call action
  };

  const handleSendMessage = () => {
    // Handle send message action
  };

  const handleContactSupport = () => {
    // Handle contact support action
  };

  const handleLogin = () => {
    // Handle login action
  };

  return (
    <Layout>
      <section>
        {loading ? (
          <Loader />
        ) : (
          <div>
            {codeStatus === "active" && (
              <div className="information-card">
                <div className="bg-green-100 text-green-900 p-8 rounded-lg shadow-lg">
                  <h2 className="text-2xl font-bold mb-4">Congratulations!</h2>
                  <p>Your code is active.</p>
                  <div className="mt-4">
                    <button className="bg-green-500 text-white px-4 py-2 rounded-md mr-4">
                      Call
                    </button>
                    <button className="bg-green-500 text-white px-4 py-2 rounded-md">
                      WhatsApp
                    </button>
                  </div>
                </div>
                <h3>Wonderful Information Card</h3>
                <button onClick={handleCall}>Call</button>
                <button onClick={handleSendMessage}>Send Message</button>
                <div className="container mx-auto p-4">
                  <div className="bg-white rounded-lg shadow-md p-6 mb-4">
                    {error && <p className="text-red-500 mb-4">{error}</p>}
                    <div>
                      <p className="mb-2">Name: {userDetails?.name}</p>
                      <p className="mb-2">Phone: {userDetails?.phone}</p>
                      <p className="mb-2">
                        Privacy Letter Order Number: {userDetails?.orderNumber}
                      </p>
                      <p className="mb-2">
                        Vehicle Number: {userDetails?.vehicleNumber}
                      </p>
                      <p className="mb-2">Color: {userDetails?.color}</p>
                      <p className="mb-2">Brand: {userDetails?.brand}</p>
                    </div>
                  </div>
                  <button
                    className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
                    onClick={handleActivateQR}
                  >
                    Activate QR
                  </button>

                  <form
                    onSubmit={handleSubmitMessage}
                    className="bg-white rounded-lg shadow-md p-6 mt-4"
                  >
                    <input
                      type="radio"
                      name="message"
                      value="msg1"
                      onChange={(e) => setMessage(e.target.value)}
                    />{" "}
                    Message 1<br />
                    <input
                      type="radio"
                      name="message"
                      value="msg2"
                      onChange={(e) => setMessage(e.target.value)}
                    />{" "}
                    Message 2<br />
                    <input
                      type="radio"
                      name="message"
                      value="msg3"
                      onChange={(e) => setMessage(e.target.value)}
                    />{" "}
                    Message 3<br />
                    <input
                      type="radio"
                      name="message"
                      value="other"
                      onChange={(e) => setMessage(e.target.value)}
                    />{" "}
                    Other
                    <br />
                    {message === "other" && (
                      <input
                        type="text"
                        value={customMessage}
                        onChange={handleCustomMessageChange}
                        placeholder="Enter custom message"
                        className="mt-2 p-2 border rounded-md w-full"
                      />
                    )}
                    <button
                      type="submit"
                      className="mt-4 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
                    >
                      Submit
                    </button>
                  </form>
                </div>
              </div>
            )}
            {codeStatus === "draft" && (
              <div className="warning-card">
                <div className="bg-yellow-100 text-yellow-900 p-8 rounded-lg shadow-lg">
                  <h2 className="text-2xl font-bold mb-4">Draft Mode</h2>
                  <p>Your code is not active.</p>
                  <div className="mt-4">
                    <button className="bg-yellow-500 text-white px-4 py-2 rounded-md">
                      Contact Support
                    </button>
                  </div>
                </div>
                <h3>Code is not active</h3>
                <button onClick={handleContactSupport}>Contact Support</button>
                <button onClick={handleActivateQR}>Activate QR</button>


                <div className="warning-card flex flex-col items-center justify-center">
      <div className="bg-yellow-100 text-yellow-900 p-8 rounded-lg shadow-lg">
        <h2 className="text-3xl font-bold mb-4">Draft Mode</h2>
        <p className="text-lg mb-4">Your code is not active.</p>
        <div className="flex justify-center space-x-4">
          <button 
            className="bg-yellow-500 text-white px-6 py-3 rounded-md hover:bg-yellow-600 transition duration-300"
            onClick={handleContactSupport}
          >
            Contact Support
          </button>
          <button 
            className="bg-yellow-500 text-white px-6 py-3 rounded-md hover:bg-yellow-600 transition duration-300"
            onClick={handleActivateQR}
          >
            Activate QR
          </button>
        </div>
      </div>
    </div>
              </div>
            )}
            {codeStatus === "expired" && (
              <div className="warning-card">
                <div className="bg-red-100 text-red-900 p-8 rounded-lg shadow-lg">
                  <h2 className="text-2xl font-bold mb-4">Code Expired!</h2>
                  <p>Your code has expired.</p>
                  <div className="mt-4">
                    <button className="bg-red-500 text-white px-4 py-2 rounded-md">
                      Login
                    </button>
                  </div>
                </div>
                <h3>Your code has expired</h3>
                <button onClick={handleLogin}>Login</button>
              </div>
            )}
          </div>
        )}
      </section>
    </Layout>
  );
};

export default Insights;
